// Fonts

@import '_fonts';

// Variables
@import 'variables';
@import '_buttons';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Header css
@import '_header';

// Footer css
@import '_footer';

// Pages
@import '_front';
@import '_products';
@import '_cart';
@import '_checkout';
@import '_contact';
@import '_subpage';

// Responsive styles
@import '_mobile';

@media screen and (min-resolution: 120dpi) {
    body {
        zoom: 0.8;
    }
}

body {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-headlines;
}

h1.product-title {
	font-size: 1.5rem;
}

.container {
  max-width: 1366px;
}

.py-4 {
  padding: 0 !important;
}

.pt-80, .p-80 {
  padding: 80px 0;
}

img {
  max-width: 100% !important;
}

.accent-red {
  background: $accent-color-red;
  border: 1px solid $accent-color-red;
}

.show-for-small {
    display: none;
}

button:hover {
	text-decoration: none !important;
}

