.card.light-grey {
	background: $light-grey;
	border: none;
	border-radius: 0;
}

.divTableRow {
    display: table-row;
}

.divTableCell.bold {
    display: table-cell;
    width: 50%;
    font-weight: 700 !important;
    padding: 3px 10px 3px 0;
}

.divTableCell {
    display: table-cell;
    width: 50%;
    padding: 3px 60px 3px 0;
}

.divTableBody {
    display: table-row-group;
}

.row.dackhotell {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%), url(/images/dackhotell.jpeg) no-repeat;
	h1 {
		color: $white;
	}
	p {
		color: $white;
	}
}

span.hotelPrice {
	display: block;
  font-size: 1.75rem !important;
  color: #ff4338 !important;
  font-weight: 700 !important;
}

.card-tyre-hotel {
  background: #ff4338 !important;
  border-radius: 40px;

  .most-picked {
    position: absolute;
    bottom: 220px;
    right: -25px;
    rotate: -20deg;
    scale: 100%;

    p {
      font-family: $font-family-headlines;
      padding: 0px;
      margin: 0px;
      line-height: 1.2;
    }
  }

  h4 {
    font-size: 2rem !important;
    font-weight: 500 !important;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 3px;
  }

  .yellow-divider {
    display: flex;
    height: 4px;
    background: #ffed4a;
    justify-content: center;
    margin-left: 80px;
    margin-right: 80px;
  }

  ul {
    font-family: $font-family-headlines;
    margin-top: 20px;
    color: #fff;
    padding: 0 0 0 25px !important;
    line-height: 1.7;
    font-size: 1.2rem !important;

    li.yellow {
      color: #ffed4a;
    }

    p.empty-li {
      height: 20px;
    }
  }

  .price-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    font-family: $font-family-headlines;
    color: #fff;

    .title {
      font-size: 1.2rem !important;
      font-weight: 400 !important;
      margin-bottom: 0px;
      padding-bottom: 10px;
    }

    .price {
      font-size: 2.5rem !important;
      font-weight: 700 !important;
      text-align: right;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

@media (max-width: 900px) {
  .card-tyre-hotel {
  background: #ff4338 !important;
  border-radius: 40px;

  .most-picked {
    position: absolute;
    bottom: 150px;
    right: -35px;
    rotate: -20deg;
    scale: 70%;

    p {
      font-family: $font-family-headlines;
      padding: 0px;
      margin: 0px;
      line-height: 1.2;
    }
  }

  h4 {
    font-size: 2rem !important;
    font-weight: 500 !important;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 3px;
  }

  .yellow-divider {
    display: flex;
    height: 4px;
    background: #ffed4a;
    justify-content: center;
    margin-left: 80px;
    margin-right: 80px;
  }

  ul {
    font-family: $font-family-headlines;
    margin-top: 20px;
    color: #fff;
    padding: 0 0 0 25px !important;
    line-height: 1.7;
    font-size: 1.2rem !important;

    li.yellow {
      color: #ffed4a;
    }

    p.empty-li {
      height: 20px;
    }
  }

  .price-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    font-family: $font-family-headlines;
    color: #fff;

    .title {
      font-size: 1rem !important;
      font-weight: 400 !important;
      margin-bottom: 0px;
      padding-bottom: 10px;
    }

    .price {
      font-size: 1.7rem !important;
      font-weight: 700 !important;
      text-align: right;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}
}

.card.hotel {
	ul {
		margin: 0;
		padding: 0 0 0 25px !important;
		line-height: 1.7;
	}
}

h4.hotel {
  &:before {
    content: "";
    display: block;
    width: 93px;
    height: 132px;
    background: url(/images/brons.png) no-repeat;
    background-size: contain;
    background-position: top left;
    margin: 0 0 10px 0;
  }
}

h4.brons {
	&:before {
		content: "";
		display: block;
		width: 93px;
		height: 132px;
		background: url(/images/brons.png) no-repeat;
		background-size: contain;
		background-position: top left;
		margin: 0 0 10px 0;
	}
}

h4.silver {
  &:before {
    content: "";
    display: block;
    width: 93px;
    height: 132px;
    background: url(/images/silver.png) no-repeat;
    background-size: contain;
    background-position: top left;
		margin: 0 0 10px 0;
  }
}

h4.guld {
  &:before {
    content: "";
    display: block;
    width: 93px;
    height: 132px;
    background: url(/images/guld.png) no-repeat;
    background-size: contain;
    background-position: top left;
		margin: 0 0 10px 0;
  }
}
