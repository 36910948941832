.searchHeader {
  display: block;
  margin: 30px 0 20px 0;
}
.searchHeader .carTitle {
  font-size: 1.25rem;
  margin: 0 5px 0 0;
}
.searchHeader .regno {
  font-size: 1.25rem;
  font-weight: 700 !important;
  margin: 0 15px 0 0;
}
.searchHeader .backButton {
  display: inline-block;
  padding: 8px 20px;
  background: #ff4338 !important;
  color: #fff !important;
  margin: 0 0 0 30px;
  position: relative;
  top: -3px;
	&:hover {
		display: inline-block;
		padding: 8px 20px;
		background: $accent-color-yellow;
		color: #000 !important;
		margin: 0 0 0 30px;
		position: relative;
		top: -3px;
		text-decoration: none;
	}
}

.filter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.filter-row h4 {
  font-size: 0.9rem;
  color: #000 !important;
  font-weight: 300 !important;
}

.filter-row .column.one {
    width: calc(66.6668% - 30px);;
		a {
			border: none !important;
			background: #fff !important;
			border-bottom: 2px solid #ff4338 !important;
			border-radius: 0;
			font-size: 1rem;
			font-weight: bold;
			color: #000;
			margin: 10px 30px 0 0 !important;
			padding: 0.55em;
		}
		a.active {
			border: none !important;
			background: #ff4338 !important;
			border-bottom: 2px solid #000 !important;
			border-radius: 0;
			font-size: 1rem;
			font-weight: bold;
			color: #fff;
			margin: 10px 30px 0 0 !important;
			padding: 0.55em;
		}
}

.filter-row .column.two,
.filter-row .column.three {
  width: calc(16.6666% - 30px);
}
.filter-row select {
  display: block;
  width: 100%;
  margin-bottom: 1em;
  padding: 0.55em 0;
  border: 0;
  border-bottom: 2px solid #ff4338 !important;
	background: #fff !important;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.15em;
  border-radius: 0;
}
.filter-row select:focus, .filter-row select:active {
  outline: 0;
  border-bottom-color: red;
}
.filterButton {
  display: inline-block;
  float: right;
  background: url('/images/search.png') no-repeat #ff4338 !important;
  background-position: 20% 50% !important;
  padding: 10px 20px 10px 38px;
  color: #fff !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  border: none !important;
	&:hover {
		display: inline-block;
		float: right;
		background: url('/images/search-black.png') no-repeat $accent-color-yellow;
		background-position: 20% 50% !important;
		padding: 10px 20px 10px 38px;
		color: #000 !important;
		font-size: 1rem !important;
		font-weight: 300 !important;
		border: none !important;
		text-decoration: none;
	}
}

.product-list-bg {
  background: $light-grey;
  padding: 80px 0;
}

.wheel-rim-list-bg {
    background: $light-grey;
    padding: 40px 0;
		img {
			width: 110px;
			height: 110px;
			object-fit: contain;
		}
}

h6.product-title {
  font-weight: 500;
  a {
    color: $text-color;
    text-transform: uppercase;
    font-size: 1.6rem;
  }
}

.underrubrik {
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: $font-family-headlines;
}

.iconGas {
  display: inline-block;
  margin: 0 15px 15px 0;
}
.iconGas:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 7px;
  background: url(/images/icon-gas.png) no-repeat;
  background-size: contain;
  background-position: center;
  width: 32px;
  height: 32px;
}
.iconGas span {
  display: inline-block;
  background: #000 !important;
  color: #fff !important;
  font-family: "Roboto Slab", serif !important;
  font-weight: 400 !important;
  padding: 3px 12px 3px 0;
  margin: 0 0 0 10px;
}
.iconGas span:before {
  content: "";
  display: inline-block;
  position: relative;
  left: -8px;
  top: 0px;
  width: 12px;
  height: 12px;
  background: url(/images/icon-left.svg) no-repeat transparent;
  background-size: contain;
}
.iconRain {
  display: inline-block;
  margin: 0 15px 15px 0;
}
.iconRain:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 7px;
  background: url(/images/icon-rain.png) no-repeat;
  background-size: contain;
  background-position: center;
  width: 32px;
  height: 32px;
}
.iconRain span {
  display: inline-block;
  background: #000 !important;
  color: #fff !important;
  font-family: "Roboto Slab", serif !important;
  font-weight: 400 !important;
  padding: 3px 12px 3px 0;
  margin: 0 0 0 10px;
}
.iconRain span:before {
  content: "";
  display: inline-block;
  position: relative;
  left: -8px;
  top: 0px;
  width: 12px;
  height: 12px;
  background: url(/images/icon-left.svg) no-repeat transparent;
  background-size: contain;
}
.iconVol {
  display: inline-block;
  margin: 0 15px 15px 0;
}
.iconVol:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 7px;
  background: url(/images/icon-vol.png) no-repeat;
  background-size: contain;
  background-position: center;
  width: 32px;
  height: 32px;
}
.iconVol span {
  display: inline-block;
  background: #000 !important;
  color: #fff !important;
  font-family: "Roboto Slab", serif !important;
  font-weight: 400 !important;
  padding: 3px 12px 3px 0;
  margin: 0 0 0 10px;
}
.iconVol span:before {
  content: "";
  display: inline-block;
  position: relative;
  left: -8px;
  top: 0px;
  width: 12px;
  height: 12px;
  background: url(/images/icon-left.svg) no-repeat transparent;
  background-size: contain;
}

.iconSnow {
    display: inline-block;
    margin: 0 15px 15px 0;
}

.iconSnow:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 7px;
    background: url(/images/snowflake.png) no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 26px;
}

.iconSnow span {
    display: none;
}

.iconSnowThumb {
		position: absolute;
		top: 5px;
		right: 15px;
    display: inline-block;
    margin: 0 15px 15px 0;
}

.iconSnowThumb:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 7px;
    background: url(/images/snowflake.png) no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 26px;
}

.iconSnowThumb span {
    display: none;
}

.iconSun {
    display: inline-block;
    margin: 0 15px 15px 0;
}

.iconSun:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 7px;
    background: url(/images/sun.png) no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 26px;
}

.iconSun span {
    display: none;
}

.iconSunThumb {
		position: absolute;
		top: 5px;
		right: 15px;
    display: inline-block;
    margin: 0 15px 15px 0;
}

.iconSunThumb:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 7px;
    background: url(/images/sun.png) no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 26px;
}

.iconSunThumb span {
    display: none;
}

.iconSnowSun {
    display: inline-block;
    margin: 0 15px 15px 0;
}

.iconSnowSun:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 7px;
    background: url(/images/snowsun.png) no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 26px;
}

.iconSnowSun span {
    display: none;
}

.iconSnowSunThumb {
		position: absolute;
		top: 6px;
		right: 15px;
    display: inline-block;
    margin: 0 15px 15px 0;
}

.iconSnowSunThumb:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 7px;
    background: url(/images/snowsun.png) no-repeat;
    background-size: contain;
    background-position: center;
    width: 26px;
    height: 26px;
}

.iconSnowSunThumb span {
    display: none;
}

h3.price {
  color: $accent-color-red;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  line-height: 1rem;
}

span.montering {
  display: block;
  color: $accent-color-red;
  font-family: $font-family-sans-serif;
  font-weight: 700;
}

.antal-label {
  font-size: 1.25rem;
  font-weight: 300;
}

input.qty {
  max-width: 30%;
  border: none !important;
  border-bottom: 2px solid $accent-color-red;
  padding: 15px;
  font-size: 1.25rem;
  margin: 0 0 10px 0;
}

.stock {
  &:before {
    content: "";
    background: url(/images/stock.svg) no-repeat;
    background-size: contain;
    display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    top: 10px;
    margin: 0 5px 0 0;
  }
}

span.instock {
  color: $green;
}

span.outstock {
  color: $red;
}

#list-view .product-image {
  width: 250px;
  height: 250px;
  text-align: center;
	margin: 0 30px 0 0;
	img {
		width: 250px !important;
		height: 250px !important;
		object-fit: contain;
		object-position: center;
	}
}

#list-view .product-image-small {
  width: 110px;
  height: 110px;
  text-align: center;
  margin: 0 30px 0 0;
  img {
    width: 110px !important;
    height: 110px !important;
    object-fit: contain;
    object-position: center;
  }
}

#grid-view .product-image {
    width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto 10px auto;

    img {
        width: 100px !important;
        height: 100px !important;
        object-fit: contain;
        object-position: center;
    }
}

#grid-view .rims .product-image {
    width: 250px !important;
    height: 250px !important;
    text-align: center;
    margin: 0 auto 10px auto;

    img {
        width: 250px !important;
        height: 250px !important;
        object-fit: contain;
        object-position: center;
    }
}

#grid-view {
	span.price {
		font-size: 1.5rem;
	}
}

.product-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  img {
    max-height: 250px;
  }
  h4 {
    color: $text-color;
		font-size: 1rem;
		margin: 10px 0;
		font-weight: 700;
  }
  p {
    color: $text-color;
  }
  .price {
    display: block;
    color: $accent-color-red;
    padding-bottom: 15px;
    font-weight: 700;
    font-size: 1.1rem;
  }
	a {
		color: #000;
		font-size: 1.1rem;
	}
}

#grid-view .product-card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    img {
        max-height: 250px;
    }
    h4 {
        color: $text-color;
				display: block;
				display: -webkit-box;
				max-width: 100%;
				height: 72px;
				margin: 0 auto;
				font-size: 20px;
				line-height: 1.2;
				-webkit-line-clamp:3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
    }
    p {
        color: $text-color;
				display: block;
				display: -webkit-box;
				max-width: 100%;
				height: 38.4px;
				margin: 0 auto;
				font-size: 16px;
				line-height: 1.2;
				-webkit-line-clamp:3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
    }
    .price {
        display: block;
        color: $accent-color-red;
        padding-bottom: 15px;
        font-weight: 700;
        font-size: 1.75rem;
    }
}



.list-view {
	display: inline-block;
	width: 24px;
	height: 24px;
	background: url(/images/list-view.svg) no-repeat !important;
	background-size: contain !important;
	margin: 0 10px 0 0;
	padding: 0 !important;
	border: none !important;
	span {
		display: none !important;
	}
	&:after {
		display: none !important;
	}
}

.list-view.active {
	display: inline-block;
	width: 24px;
	height: 24px;
	background: url(/images/list-view-active.svg) no-repeat !important;
	background-size: contain !important;
	margin: 0 10px 0 0;
	padding: 0 !important;
	border: none !important;
	span {
	    display: none !important;
	}
	&:after {
	    display: none !important;
	}
}

.grid-view {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(/images/grid-view.svg) no-repeat !important;
    background-size: contain !important;
    margin: 0 !important;
		padding: 0 !important;
		border: none !important;
    span {
        display: none !important;
    }
		&:after {
		    display: none !important;
		}
}

.grid-view.active {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(/images/grid-view-active.svg) no-repeat !important;
    background-size: contain !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    span {
        display: none;
    }
		&:after {
		    display: none !important;
		}
}

.related-header {
	display: block;
	background: $accent-color-red;
	padding: 15px 20px 10px 20px;
	max-width: 98% !important;
}

.other-header {
    display: block;
    background: $accent-color-red;
    padding: 15px 20px 10px 20px;
    max-width: 100% !important;
}

h1.product-title {
	a {
		color: $black;
	}
}

.pagination {
	.page-item {
		a {
			color: $accent-color-red;
		}
	}
	.page-item.disabled {
		a {
			color: #666 !important;
		}
	}
}

h2 {
	span {
		color: #666;
		font-size: 1.2rem;
	}
}

.card.unselected {
	opacity: .3;
}

.dropdown-toggle {
	border: none !important;
	border-bottom: 2px solid $accent-color-red;
	background: #fff !important;
	border-radius: 0 !important;
	span.search-title {
		display: block;
		text-align: left;
		font-size: 1.25rem;
		color: #000 !important;
	}
	span.search-car {
		display: block;
		text-align: left;
		font-size: 1rem;
		color: #000 !important;
	}
}

.dropdown-toggle::after {
	position: absolute;
	top: 50%;
	right: 10px;
	border-color: #000 !important;
  border-top: .5em solid #000 !important;
  border-right: .5em solid transparent !important;
  border-bottom: 0 !important;
  border-left: .5em solid transparent !important;
}

.dropdown-menu.red {
	background: $accent-color-red;
	width: 100%;
  transform: translate3d(-4px, 72px, 0px) !important;
}

.dropdown .btn-secondary:focus,
.dropdown .btn-secondary.focus {
    box-shadow: none !important;
}

.dropdown-item {
	display: block;
	background: $accent-color-red;
	color: #fff;
	border-bottom: 2px solid #fff;
	padding: 10px 0;
	text-align: center;
	margin: 0 0 3px 0;
	transition: all 0.4s ease;
	min-width: 100%;
	&:hover {
		display: block;
		background: $color-white;
		color: $color-black;
		border-bottom: 2px solid $color-white;
		padding: 10px 0;
		text-align: center;
		margin: 0 0 3px 0;
		transition: all 0.4s ease;
	}
}


.dropdown-menu input.searchBig {
    padding: 20px 20px 20px 40px;
    margin: 30px 0 0 0;
    border: none;
    font-size: 3rem;
    text-align: center;
    font-weight: 700 !important;
    letter-spacing: -0.05rem;
    max-width: 100%;
    background: url(/images/reg_bg_new.png) no-repeat #fff;
    background-position: 0 50%;
}

.dropdown .searchButton {
	max-width: 100%;
}

#list-view {
	.brand-image {
		position: absolute !important;
		z-index: 2;
		bottom: 30px;
		left: 30px;
	}
}

#list-view .product-image {
	position: relative !important;
	z-index: 1;
}

.product-details {
    .brand-image {
        position: absolute !important;
        z-index: 2;
        bottom: 30px;
        left: 30px;
    }
}

.product-details .product-image {
    position: relative !important;
    z-index: 1;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

	.container.filter-container {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.navbar.filter .has-megamenu {
		position: static !important;
	}

	.navbar.filter .megamenu {
		left: 0;
		right: 0;
		width: 100%;
		margin-top: 0;
		h4 {
			display: inline-block;
			margin-top: 30px;
		}
	}

	.navbar.filter {
		display: block;
		border: none !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.navbar.filter>.container,
	.navbar.filter>.container-fluid,
	.navbar.filter>.container-lg,
	.navbar.filter>.container-md,
	.navbar.filter>.container-sm,
	.navbar.filter>.container-xl,
	.navbar.filter>.container-xxl {
		display: block;
		margin: 0;
		padding: 0;
	}

	.navbar.filter .navbar-expand-lg .navbar-collapse {
		display: block !important;
		flex-basis: auto;
	}
	.navbar.filter .navbar-nav {
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		.nav-item.chosen {
			display: inline-block;
			width: calc(33.333333% - 10px);
			margin: 0 0 0 0;
		}
		.nav-item.dimension,
		.nav-item.search-content,
		.nav-item.season,
		.nav-item.brand,
		.nav-item.width,
		.nav-item.profile,
		.nav-item.inch,
		.nav-item.button {
      cursor: pointer;
			display: inline-block;
			width: calc(16.66666667% - 10px);
			margin: 0;
		}
	}

	.navbar.filter .dropdown-toggle {
		background: url(/images/down-arrow.svg) no-repeat !important;
		background-size: 16px 16px !important;
		background-position: 95% 50% !important;
		&:after {
			display: none;
		}
	}

	.navbar.filter .dropdown-toggle::after {
		position: relative;
		top: 50%;
		right: 10px;
		border-color: #000 !important;
		border-top: .5em solid #000 !important;
		border-right: .5em solid transparent !important;
		border-bottom: 0 !important;
		border-left: .5em solid transparent !important;
	}

	.navbar.filter .dropdown-menu {
		background: $accent-color-red;
		padding: 20px;
		.filter-button {
			display: block;
			width: 100%;
			background: none;
			border: none;
			text-align: center;
			border-bottom: 1px solid #fff;
			color: #fff;
			padding: 5px 0;
			margin: 0 0 10px 0;
			transition: all 0.4s ease;
			&:hover {
				display: block;
				width: 100%;
				background: #fff;
				border: none;
				text-align: center;
				border-bottom: 1px solid #fff;
				color: $accent-color-red;
				padding: 5px 0;
				margin: 0 0 10px 0;
				transition: all 0.4s ease;
				text-decoration: none;
			}
		}
	}

}

/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 991px) {

	.navbar.filter .navbar.fixed-top .navbar-collapse,
	.navbar.filter .navbar.sticky-top .navbar-collapse {
		overflow-y: auto;
		max-height: 90vh;
		margin-top: 10px;
	}

	.navbar.filter {
		display: block;
		border: none !important;
	}

	.navbar.filter .dropdown-menu {
		background: $accent-color-red;
		padding: 20px;

		.filter-button {
			display: block;
			width: 100%;
			background: none;
			border: none;
			text-align: center;
			border-bottom: 1px solid #fff;
			color: #fff;
			padding: 5px 0;
			margin: 0 0 10px 0;
			transition: all 0.4s ease;

			&:hover {
				display: block;
				width: 100%;
				background: #fff;
				border: none;
				text-align: center;
				border-bottom: 1px solid #fff;
				color: $accent-color-red;
				padding: 5px 0;
				margin: 0 0 10px 0;
				transition: all 0.4s ease;
			}
		}
	}

	.navbar.filter .navbar-nav {
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		.nav-item.chosen {
			display: block;
			width: 100%;
			margin: 0;
		}
		.nav-item.dimension,
		.nav-item.search-content,
		.nav-item.season,
		.nav-item.brand {
			display: block;
			width: 100%;
			margin: 0;
		}
	}


}

/* ============ mobile view .end// ============ */
