.jumbotron {
  background: url(/images/home-hero.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  padding: 5rem 2rem;
  border-radius: 0;
}

.jumbotron-home {
  background: url(/images/home_banner.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  padding: 3rem 2rem;
  border-radius: 0;
}

.jumbotron-tyrehotel {
  background: url(/images/tyrehotel_banner.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  padding: 3rem 2rem;
  border-radius: 0;
}

.jumbotron-carservice {
  background: url(/images/carservice_banner.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  padding: 3rem 0rem;
  border-radius: 0;
}

.nav-pills .nav-link.active:after {
  content: '';
  position: absolute;
  left: calc(50% - 15px);
  top: 42px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid $white;
  clear: both;

}

.nav-pills .nav-link {
  color: $white;
  border: 2px solid $white;
  font-family: $font-family-headlines;
  margin: 0 15px 0 15px;
  border-radius: 0;
}

.nav-pills .nav-link.active {
  background: $white;
  color: #000 !important;
  font-family: $font-family-headlines;
  margin: 0 15px 0 15px;
  border-radius: 0;
  position: relative
}

.tab-content.search {
  max-width: 100%;
  margin: 50px 0;
}

.container.intro {
  max-width: 950px;
  text-align: center;
  padding: 80px 0;
  h2 {
    font-weight: 700;
    &:after {
      content: "";
      display: block;
      width: 200px;
      height: 4px;
      background: #ff4338 !important;
      margin: 30px auto 30px auto;
    }
  }
  p {
    font-size: 1.25rem !important;
    line-height: 1.9rem;
  }
}

.blurbs-section {
  background: $light-grey;
  padding: 80px 0;
  .row-flex {
    display: flex;
    flex-wrap: wrap;
    .content {
      text-align: left;
      img {
        max-width: 100%;
        margin: 0 0 30px 0;
      }
      h3 {
        line-height: 2.8rem;
        font-size: 2rem !important;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 208.4px;
        margin: 0 auto;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
      }
    }
  }
}

.media-section {
  padding: 0;
  .row {
    h3 {
      font-weight: 700;
    }
  }
}

input.searchBig {
    padding: 20px 20px 20px 40px;
    margin: 30px 0 0 0;
    border: none;
    font-size: 3rem;
    text-align: center;
    font-weight: 700 !important;
    letter-spacing: -0.05rem;
    max-width: 450px;
    background: url(/images/reg_bg_new.png) no-repeat #fff;
    background-position: 0 50%;
}

input.searchBig::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #dfdfdf;
}

input.searchBig::-moz-placeholder {
    /* Firefox 19+ */
    color: #dfdfdf;
}

input.searchBig:-ms-input-placeholder {
    /* IE 10+ */
    color: #dfdfdf;
}

input.searchBig:-moz-placeholder {
    /* Firefox 18- */
    color: #dfdfdf;
}

.searchButton {
    display: block;
    width: 450px;
		background: transparent !important;
		border: none !important;
    border-bottom: 2px solid $white !important;
    margin: 20px auto;
    padding: 10px 0;
    color: $white !important;
    font-weight: 400 !important;
    transition: all 0.4s ease;
		text-align: center;
		font-size: 20px;
}

.searchButton span {
    display: inline-block;
}

.searchButton:hover {
    background: #ffffff !important;
    color: #ff4338 !important;
    transition: all 0.4s ease;
}

.section-one {
  .card {
    background: $accent-color-red;
		display: block;
		text-align: center;
    height: 100%;
		.row {
			display: block;
		}
  }
}

.full-select {
	display: block;
	width: 100%;
	background: $accent-color-red;
	padding: 10px 10px 2px 10px;
	select {
  	display: block;
  	width: 100%;
		margin-bottom: 1em;
		padding: 15px;
		border: 0;
		border-bottom: 2px solid #fff !important;
		background: #fff;
		color: #000;
		font-size: 1rem;
		border-radius: 0;

    padding: 5px 10px;
    border: 1px solid #fff;

    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
	}
}

.third-select {
	width: 33%;
	background: $accent-color-red;
	padding: 10px 10px 0 10px;
	select {
  	display: block;
  	width: 100%;
		margin-bottom: 1em;
		padding: 0.55em 0;
		border: 0;
		border-bottom: 2px solid #fff !important;
		background: #fff;
		color: #000;
		font-size: 1rem;
		border-radius: 0;

    padding: 5px 10px;
    border: 1px solid #fff;

    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
	}
}

.third-select:nth-child(1) {
	margin-right: 5px;
}

.third-select:nth-child(2) {
	margin: 0;
}

.third-select:nth-child(3) {
	margin-left: 5px;
}

.wheel {
	display: block;
	width: 100%;
	height: 221px;
	background: url(/images/hjul.png) no-repeat;
	background-size: contain;
	background-position: top center;
}

.hjul-bredd, .hjul-profil, .hjul-tum {
	display: inline-block;
	position: relative;
	font-size: 35px;
	top: 35px;
	margin-left: 3px;
	margin-right: 3px;
}

@media (max-width: 767px) {
  .hjul-bredd, .hjul-profil, .hjul-tum {
    top: 15px;
  }
}

a.fx-Revolve {
	&:focus {
		// outline: 0;
		box-shadow: 0 0 3pt 2pt #75ACF8;
		position: relative;
		outline: 0;
		&:before {
			border-radius: inherit;
			content: "";
			width: calc(100% - 2.2em);
			height: calc(100% - 2.2em);
			margin: 1em;
			box-shadow: inset 0 0 3pt 2pt #75ACF8;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}
