.cart-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.remove {
	white-space:nowrap;
}

.product-card p {
	color: $text-color;
	display: block;
	display: -webkit-box;
	max-width: 100%;
	height: 38.4px;
	margin: 0 auto;
	font-size: 16px;
	line-height: 1.2;
	-webkit-line-clamp:3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table-responsive {
	.addtocart {
		margin: 0;
	}
}

.row-inner {
	margin: 0 !important;
	padding: 0 !important;
	display: block !important;
	border-bottom: 1px solid #ddd;
}

.cart-cursor {
	cursor: pointer !important;
}

.table-responsive {
	.product {
		width: 70%;
	}
	.price {
		width: 10%;
	}
	.amount {
		width: 10%;
	}
	.remove {
		cursor: pointer !important;
		width: 10%;
	}
}

.table-responsive td {
	border-top: none;
}

.table>:not(caption)>*>* {
	border-bottom-width: 0;
}

.product-row {
	font-size: 1.1rem;
	border-bottom: 1px solid #ddd;
		.yes {
		  display: inline-block;
		  margin: 0 0 0 10px;
		  background: $green;
		  color: $white;
		  padding: 5px 10px;
			&:hover {
				text-decoration: none;
				background: #30a260;
			}
		}
		.no {
		  display: inline-block;
		  margin: 0 0 0 10px;
		  background: $red;
		  color: $white;
		  padding: 5px 10px;
			&:hover {
			  text-decoration: none;
			  background: #c12924;
			}
		}
}
