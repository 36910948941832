// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-headlines: 'Roboto Slab', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$accent-color-red: #ff4338 !important;
$accent-color-yellow: #fdeb30 !important;
$light-grey: #f6f6f7 !important;
$footer-bg: #191919 !important;
$text-color: #191919 !important;
$color-white: #fff !important;
$color-black: #000 !important;
