.footer-black {
  background: $footer-bg;
  padding: 80px 0;
  img.footer-logo {
    max-width: 100%;
  }
  a {
    color: $white;
    font-weight: 700;
  }
}
