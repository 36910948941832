@media (max-width 1366px) {

	html, body {
		overflow-x: hidden;
	}

	body .navbar-light .navbar-toggler-icon {
		background: url(/images/hamburger.svg) no-repeat !important;
	}

	.blurbs-section .row-flex .content h3 {
		line-height: 2.2rem;
		font-size: 1.4rem !important;
		display: block;
		display: -webkit-box;
		max-width: 100%;
		height: 208.4px;
		margin: 0 auto;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 700;
	}

	body .p-80 {
		padding: 80px 20px !important;
	}

}

@media (max-width: 1200px) {
	.cart-header .hide-for-small {
		display: none !important;
	}
}

@media (max-width: 767px) {

	.row {
		max-width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.jumbotron {
	    background: url(/images/home-hero.jpeg) no-repeat;
	    background-size: cover;
	    background-position: center;
	    color: #fff;
	    padding: 5rem 0rem;
	    border-radius: 0;
	}

	.search-button {
		display: inline-block;
		background: url(/images/search-dimension.svg) no-repeat #ff4338 !important;
		background-position: 88% 50% !important;
		background-size: 20px 20px !important;
		padding: 10px 36px 10px 20px !important;
		color: #fff !important;
		margin: 20px 0 20px 0;
		transition: all 0.4s ease;
		font-size: 1rem !important;
		&:hover {
			display: inline-block;
			background: url(/images/search-dimension-black.svg) no-repeat #fdeb30 !important;
			background-position: 88% 50% !important;
			background-size: 20px 20px !important;
			padding: 10px 36px 10px 20px !important;
			color: #000 !important;
			margin: 20px 0 20px 0;
			transition: all 0.4s ease;
			font-size: 1rem !important;
			text-decoration: none;
			cursor: pointer;
		}
	}

	input.searchBig {
	padding: 10px 20px 10px 40px !important;
	margin: 30px 0 0 0;
	border: none;
	font-size: 3rem;
	text-align: center;
	font-weight: 700 !important;
	letter-spacing: -0.05rem;
	max-width: 100%;
	background: url(/images/reg_bg_new.png) no-repeat #fff;
	background-position: 0 50%;
	}

	.sticky .collapse:not(.show) {
    display: none !important;
	}

	#filter_nav.collapse:not(.show) {
    display: block !important;
	}

	.navbar-toggler {
		display: block;
		width: 100%;
		border: none !important;
	}

	ul.navbar-nav {
		display: block;
		width: 100%;
		li {
			a {
				text-align: center;
				padding: 15px 0;
				border-bottom: 1px solid rgba(0,0,0,.3);
			}
		}
		li:first-child {
			margin-top: 20px;
		}
		li:last-child {
			a {
				text-align: center;
				padding: 15px 0;
				border-bottom: 0px solid rgba(0,0,0,.3);
			}
		}
	}

	.navbar-brand {
		display: block;
		width: 409px;
		height: 46px;
		margin: auto;
	}

	.container.intro {
		.col {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.embed-responsive {
	  position: relative;
	  display: block;
	  width: 100%;
	  padding: 0;
	  overflow: hidden;
	  margin-bottom: 30px;
	}

	.hide-for-small {
		display: none;
	}

	.show-for-small {
		display: block !important;
		padding-left: 15px;
		padding-right: 15px;
	}

	/* TEST OVERLAY */

	.search-icon {
	    display: block;
	    background-color: $accent-color-red;
	    color: #fff;
	    padding: 15px 0;
			margin: 0 0 20px 0;
			text-align: center;
	    font-size: 20px;
			font-style: normal;
	    cursor: pointer;
	}

	.search-screen {
	    position: fixed;
	    width: 100%;
	    height: 0;
	    background-color: rgba(255,255,255,.9);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    left: 0;
	    top: 50%;
	    transform: translateY(-50%);
	    overflow: hidden;
	    transition: .3s linear .3s;
			z-index: 99999999;
	}

	.close-icon {
	    position: absolute;
	    top: 40px;
	    right: 40px;
	    background: #000;
			color: $white;
	    font-size: 30px;
	    cursor: pointer;
	    opacity: 1;
			width: 40px;
			height: 40px;
			text-align: center;
			font-style: normal;
			line-height: 1.4;
			&:hover {
				background: $accent-color-red
			}
	}

	.search-box {
	    width: 100%;
	    max-width: 600px;
	    height: 60px;
	    background: none;
	    border: none;
	    outline: none;
	    color: #fff;
	    font-size: 60px;
	}

	.search-screen.active {
	    transition-delay: 0s;
	    height: 100%;
	}

	.search-screen.active .search-box,
	.search-screen.active .close-icon {
	    transition: .2s linear 0.4s;
	    opacity: 1;
	}

	.filter-row {
	    display: block;
			width:100%;
			.column.one {
				width: 100%;
				a {
				    display: block;
				    border: none !important;
				    background: #fff !important;
				    border-bottom: 2px solid #ff4338 !important;
				    border-radius: 0;
				    font-size: 1.2rem;
				    font-weight: bold;
				    color: #000;
				    margin: 10px 0 0 0 !important;
				    padding: 0.55em;
				}

				a.active {
				    border: none !important;
				    background: #ff4338 !important;
				    border-bottom: 2px solid #000 !important;
				    border-radius: 0;
				    font-size: 1.2rem;
				    font-weight: bold;
				    color: #fff;
				    margin: 10px 0 0 0 !important;
				    padding: 1em 0.55em;
				}
			}
			.column.two, .column.three {
				width: 100%;
				margin: 20px 0 0 0;
			}
	}

	.filter-row .column {
	    width: 80%;
	}

	.filter-row h4 {
	    font-size: 1.3rem;
	    color: #000 !important;
	    font-weight: 300 !important;
	}

	.filter-row select {
	    display: block;
	    width: 100%;
	    margin-bottom: 1em;
	    padding: 0.85em 0 0.85em 0.20em;
	    border: 0;
			background: $white;
	    border-bottom: 2px solid #ff4338 !important;
	    font-size: 1.2rem;
	    font-weight: bold;
	    letter-spacing: 0.15em;
	    border-radius: 0;
	}

	.blurbs-section .row-flex .content h3 {
		line-height: 2.4rem;
		font-size: 1.5rem !important;
		display: block;
		display: -webkit-box;
		max-width: 100%;
		height: auto;
		margin: 0 auto 30px auto;
		-webkit-line-clamp: unset;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 700;
	}

	.searchButton {
    display: block;
    width: 100% !important;
		background: transparent !important;
		border: none !important;
    border-bottom: 2px solid $white !important;
    margin: 20px 0;
    padding: 10px 0;
    color: $white !important;
    font-weight: 400 !important;
    transition: all 0.4s ease;
		text-align: center;
		font-size: 20px;
	}

	.searchButton span {
			display: inline-block;
	}

	.searchButton:hover {
			background: #ffffff !important;
			color: #ff4338 !important;
			transition: all 0.4s ease;
	}

	.dropdown-menu input.searchBig {
    padding: 20px 10px 20px 30px;
    margin: 30px 0 0 0;
    border: none;
    font-size: 3rem;
    text-align: center;
    font-weight: 700 !important;
    letter-spacing: -0.05rem;
    max-width: 100%;
    background: url(/images/reg_bg_new.png) no-repeat #fff;
    background-position: 0 50%;
	}

	.navbar.filter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
	}

	.dropdown-toggle::after {
    position: absolute;
    top: 37px;
    right: 20px;
    border-color: #000 !important;
    border-top: 0.5em solid #000 !important;
    border-right: 0.5em solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0.5em solid transparent !important;
	}

	.product-row {
	    font-size: 1.1rem;
	    border-bottom: 1px solid #ddd;

	    .yes {
	        display: inline-block;
	        margin: 10px 0 0 0;
	        background: $green;
	        color: $white;
	        padding: 5px 10px;

	        &:hover {
	            text-decoration: none;
	            background: #30a260;
	        }
	    }

	    .no {
	        display: inline-block;
	        margin: 10px 0 0 0;
	        background: $red;
	        color: $white;
	        padding: 5px 10px;

	        &:hover {
	            text-decoration: none;
	            background: #c12924;
	        }
	    }
	}

}


@media (max-width: 440px) {

	.nav.search {
		li {
			margin: 0 0 20px 0;
		}
	}

	input.searchBig {
			padding: 20px 20px 20px 40px;
			margin: 0;
			border: none;
			font-size: 3rem;
			text-align: center;
			font-weight: 700 !important;
			letter-spacing: -0.05rem;
			max-width: 100%;
			background: url(/images/reg_bg_new.png) no-repeat #fff;
			background-position: 0 50%;
	}

	.searchButton {
			display: block;
			width: 100% !important;
			background: transparent !important;
			border: none !important;
			border-bottom: 2px solid #fff !important;
			margin: 20px 0;
			padding: 10px 0;
			color: #fff !important;
			font-weight: 400 !important;
			transition: all 0.4s ease;
			text-align: center;
			font-size: 20px;
	}

	.footer-black {
	  background: #000 !important;
	  padding: 80px 0;
	  text-align: center;
		.col-12 {
			margin-bottom: 40px;
		}
		.footer-logo {
			max-height: 35px;
		}
	}

	.brands {
		img {
			max-width: 80% !important;
		}
	}

	#list-view .product-image {
	    width: 250px;
	    height: 250px;
	    text-align: center;
	    margin: 0;
	}

	#list-view {
		.col-3 {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.wheel {
    display: block;
    width: 100%;
    height: 145px;
    background: url(/images/hjul.png) no-repeat;
    background-size: contain;
    background-position: top center;
	}

}
