.bg_gray {
  background: $light-grey;
}

.card.contact {
  a {
    color: $text-color;
    font-weight: 700;
  }
  h3.adress {
    display: block;
    &:before {
      content: "";
      display: block;
      width: 48px;
      height: 48px;
      background: url(/images/pin.svg) no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 auto 10px auto;
    }
  }
  h3.telefon {
    display: block;
    &:before {
      content: "";
      display: block;
      width: 48px;
      height: 48px;
      background: url(/images/telefon.svg) no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 auto 10px auto;
    }
  }
  h3.epost {
    display: block;
    &:before {
      content: "";
      display: block;
      width: 48px;
      height: 48px;
      background: url(/images/epost.svg) no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 auto 10px auto;
      }
  }
}
