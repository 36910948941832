.sticky {
    background: #ffffff;
}

.fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
		z-index: 9999999;
}

.topbar {
  background: $light-grey;
  padding: 15px 0;
}

.topbarLeft {
    font-size: 0.9rem !important;
    display: flex;
    flex-direction: row;
}

.kundservice {
  display: inline-block;
  margin: 0 30px 0 0;
  align-items: center;
	position: relative;
	top: 17px;
}
.header-cart {
	display: inline-block;
	position: relative;
	top: -12px;
}
.oppet a, .kundservice a, .maila a {
  color: #000 !important;
}
.oppet a:hover, .kundservice a:hover, .maila a:hover {
  text-decoration: underline;
}
.oppet span.icon {
  width: 32px;
  height: 32px;
  background: url(/images/home.png) no-repeat #fff;
  background-position: center !important;
  font-family: "ETmodules" !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 0 10px 0 0;
}
.kundservice span.icon {
  width: 32px;
  height: 32px;
  background: url(/images/tel.png) no-repeat #fff;
  background-position: center !important;
  font-family: "ETmodules" !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 0 10px 0 0;
}
.maila span.icon {
  width: 32px;
  height: 32px;
  background: url(/images/mail.png) no-repeat #fff;
  background-position: center !important;
  font-family: "ETmodules" !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 0 10px 0 0;
}
span.text {
  line-height: 1.2rem !important;
}

.topbarRight {
    text-align: right;
}

.time {
  display: inline-block;
  background: url(/images/clock.png) no-repeat #ff4338 !important;
  background-position: 90% 50% !important;
  padding: 10px 36px 10px 20px;
  color: #fff !important;
  margin: 0 10px 0 0;
  transition: all 0.4s ease;
}
.time:hover {
  background: url(/images/clock-black.png) no-repeat #fdeb30 !important;
  background-position: 90% 50% !important;
  color: #000 !important;
  transition: all 0.4s ease;
}

a.login {
  display: inline-block;
	position: relative;
	top: 5px;
  background: url(/images/login.png) no-repeat #ff4338 !important;
  background-position: 90% 50% !important;
  padding: 10px 38px 10px 20px;
  margin: 0 10px 0 0;
  color: #fff !important;
}
a.login:hover {
	position: relative;
	top: 5px;
  background: url(/images/login-black.png) no-repeat #fdeb30 !important;
  background-position: 90% 50% !important;
  color: #000 !important;
  margin: 0 10px 0 0;
  transition: all 0.4s ease;
  text-decoration: none;
}
a.register {
  display: inline-block;
	position: relative;
	top: 5px;
  background: url(/images/login.png) no-repeat #ff4338 !important;
  background-position: 90% 50% !important;
  padding: 10px 38px 10px 20px;
  color: #fff !important;
}
a.register:hover {
	position: relative;
	top: 5px;
  background: url(/images/login-black.png) no-repeat #fdeb30 !important;
  background-position: 90% 50% !important;
  color: #000 !important;
  transition: all 0.4s ease;
  text-decoration: none;
}
.cart-contents {
  display: inline-block;
  position: relative;
  z-index: 1;
  background: url(/images/cart.png) no-repeat;
  background-position: top left;
  background-size: contain;
  width: 24px;
  height: 24px;
  top: 5px;
  margin-left: 20px;
}
.cart-contents-count {
  position: relative;
  top: -16px;
  left: 19px;
  z-index: 2;
  background: #ff4338 !important;
  color: #fff !important;
  padding: 5px 9px;
  font-size: 12px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  border-radius: 50%;
}
.cartBackground {
  display: inline-block;
}

.navbar {
  border-bottom: 5px solid $accent-color-red;
}

.navbar-brand {
  padding: 20px 0;
  .logo {
    max-height: 51px;
  }
}

.navbar-expand-md .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  justify-content: flex-start;
}

.navbar-nav {
  li {
    a {
      text-transform: uppercase;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      color: rgba(0,0,0,1) !important;
    }
  }
}

.bg-yellow {
	background: $accent-color-yellow;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}
