.readmore {
  display: inline-block;
  background: url(/images/arrow-right-white.png) no-repeat #ff4338 !important;
  background-position: 90% 50% !important;
  background-size: 24px 24px;
  padding: 10px 50px 10px 20px !important;
  color: #fff !important;
  margin: 0 10px 0 0;
  transition: all 0.4s ease;
  font-size: 1rem !important;
  &:hover {
    display: inline-block;
    background: url(/images/arrow-right-black.png) no-repeat #fdeb30 !important;
    background-position: 90% 50% !important;
    background-size: 24px 24px;
    padding: 10px 50px 10px 20px !important;
    color: #000 !important;
    margin: 0 10px 0 0;
    transition: all 0.4s ease;
    font-size: 1rem !important;
    text-decoration: none;
  }
}

.homebook {
  display: inline-block;
  background-color: #e3342f;
  padding: 10px 20px 10px 20px !important;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff !important;
  margin: 0 10px 0 0;
  font-size: 2rem !important;
  &:hover {
    background-color: #ffed4a;
    color: #000 !important;
    transition: all 0.4s ease;
    text-decoration: none;
  }
}

.tyrehotelbook {
  display: inline-block;
  background-color: #e3342f;
  padding: 10px 20px 10px 20px !important;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff !important;
  margin: 0 10px 0 0;
  font-size: 1.5rem !important;
  &:hover {
    background-color: #ffed4a;
    color: #000 !important;
    transition: all 0.4s ease;
    text-decoration: none;
  }
}

.addtocart {
  display: inline-block;
  background: url(/images/cart-white.svg) no-repeat #ff4338 !important;
  background-position: 90% 50% !important;
  background-size: 30px 30px !important;
  padding: 10px 50px 10px 20px !important;
  color: #fff !important;
  margin: 30px 0 0 0;
  transition: all 0.4s ease;
  font-size: 1rem !important;
  &:hover {
    display: inline-block;
    background: url(/images/cart-black.svg) no-repeat #fdeb30 !important;
    background-position: 90% 50% !important;
    background-size: 30px 30px !important;
    padding: 10px 50px 10px 20px !important;
    color: #000 !important;
    margin: 30px 0 0 0;
    transition: all 0.4s ease;
    font-size: 1rem !important;
    text-decoration: none;
  }
}

.btn-checkout, .btn-contact {
  display: inline-block;
  background: $accent-color-red;
  border: 1px solid $accent-color-red;
  background-position: 90% 50% !important;
  background-size: 30px 30px !important;
  padding: 10px 20px 10px 20px !important;
  color: #fff !important;
  margin: 2px 0 25px 0 !important;
  transition: all 0.4s ease;
  font-size: 1rem !important;
  &:hover {
    display: inline-block;
    background: $accent-color-yellow;
    border: 1px solid $accent-color-yellow;
    background-position: 90% 50% !important;
    background-size: 30px 30px !important;
    padding: 10px 20px 10px 20px !important;
    color: #000 !important;
    margin: 2px 0 25px 0 !important;
    transition: all 0.4s ease;
    font-size: 1rem !important;
    text-decoration: none;
  }
}

.remove {
    color: $accent-color-red;
    &:before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        position: relative;
        background: url(/images/remove.svg) no-repeat;
        background-size: contain;
        top: 6px;
        margin: 0 10px 0 0;
    }
}

#button-addon3 {
    &:hover {
        background: $accent-color-red;
    }
}

.search-button {
	display: inline-block;
	background: url(/images/search-dimension.svg) no-repeat #ff4338 !important;
  background-position: 88% 50% !important;
  background-size: 20px 20px !important;
  padding: 10px 36px 10px 20px !important;
  color: #fff !important;
  margin: 0;
  transition: all 0.4s ease;
  font-size: 1rem !important;
	&:hover {
		display: inline-block;
    background: url(/images/search-dimension-black.svg) no-repeat #fdeb30 !important;
    background-position: 88% 50% !important;
    background-size: 20px 20px !important;
    padding: 10px 36px 10px 20px !important;
    color: #000 !important;
    margin: 0;
    transition: all 0.4s ease;
    font-size: 1rem !important;
    text-decoration: none;
		cursor: pointer;
	}
}
